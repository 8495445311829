import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { purgeAll } from "@store/actions";
import { useHistory } from "react-router-dom";
import Sidebar from "@components/Layout/Sidebar";
import styles from "./index.module.scss";
import {
  changePasswordPromise,
  setSidebarState,
  getSchoolPromise,
} from "@store/actions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { setToken, getStorageData, useInterval } from "@api";

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pw, setPw] = useState("");
  const [confirmPw, setConfirmedPw] = useState("");
  const control = useSelector((state) => state.control);
  const user = useSelector((state) => state.user);
  const schoolData = useSelector((state) => state.schools.byId[user.schoolId]);

  useEffect(() => {
    const schoolId = user.schoolId;
    if (schoolId == null) {
      //link to focuspang school
      dispatch(getSchoolPromise(1424));
    }
    dispatch(getSchoolPromise(schoolId));
  }, []);

  useEffect(() => {
    if (user.token !== undefined || user.token !== null) {
      setToken(user.token).then(() => {
        try {
          console.log("set token to program...");
          window.cAPI.setToken(user.token);
        } catch (error) {}
      });
    }
    if (user.state === "REGISTERED_APPROVED") {
      if (control.sidebarState === false) dispatch(setSidebarState());
    } else {
      //dispatch(setSidebarState(false));
    }
    // console.log('[Setting : ', control);
  }, [user, control]);

  const navigateToEditMode = () => {
    // console.log('설정변경');
    // console.log("location : ", location);
  };

  const sendChangedPassword = () => {
    dispatch(
      changePasswordPromise({
        schoolId: user.schoolId,
        accountId: user.accountId,
        password: pw,
      })
    )
      .then((response) => {
        console.log(response);
        if (response === undefined) {
          alert(`비밀번호 변경에 실패했습니다.
이메일 또는 비밀번호를 확인해주세요.`);
          return;
        }
        alert(`비밀번호가 성공적으로 변경되었습니다!`);
      })
      .catch((error) => {
        console.log(error);
        alert(`비밀번호 변경에 실패했습니다.
이메일 또는 비밀번호를 확인해주세요.`);
      });
  };
  return (
    <div className={styles["main-container"]}>
      <Sidebar />
      <div className={styles["settings-container"]}>
        <div className={styles["settings-title"]}>설정</div>
        <div className={styles["passwordChange-container"]}>
          <div className={styles["settings-subtitle"]}>비밀번호 바꾸기</div>
          <br />
          <input
            className={styles["password-input"]}
            type="password"
            placeholder="새로운 비밀번호를 입력하세요"
            onChange={(e) => setPw(e.target.value)}
          />
          <input
            className={styles["password-input"]}
            type="password"
            placeholder="비밀번호를 다시 입력하세요"
            onChange={(e) => setConfirmedPw(e.target.value)}
          />
          {pw === confirmPw && pw !== "" ? (
            <button
              className={styles["password-button"]}
              onClick={sendChangedPassword}
            >
              비밀번호 바꾸기
            </button>
          ) : pw === "" ? (
            <button className={styles["password-button"]}>
              비밀번호 바꾸기
            </button>
          ) : (
            <button className={styles["password-button"]}>
              비밀번호가 일치하지 않습니다.
            </button>
          )}
        </div>
        <br />
        <br />
        <br />
        <br />
        <div style={{ color: "#eeeeee" }}>ver 2.0.1.241030</div>
        <br />
      </div>
    </div>
  );
};

export default Settings;
