import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import styles from "./InfoStudentUpdateModal.module.scss";
import useInput from '../../hooks/useInput';
import styled from 'styled-components';
import {
  changePasswordPromise,
  teacherSignUpPromise,
  updateUserInfoPromise,
  createTeacherAdminPromise,
  updateTeacherInfoAdminPromise,
  getAllStudentsAdminPromise
} from '@store/actions';
const ErrorMessage = styled.div`
  color: red;
`;

function InfoStudentUpdateModal({ show, setShow, propUsername, propStudentNum, propUser_id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [name, setName] = useState('');
  const [account, setAccount] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [email, onChangeEmail] = useInput('');
  const [grade, setGrade] = useState('');
  const [classNum, setClassNum] = useState('');
  const [studentNum, setStudentNum] = useState('');
  const [password, onChangePassword] = useInput('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const accountHandler = (e) => setAccount(e.target.value);
  const gradeHandler = (e) => setGrade(e.target.value);
  const classNumHandler = (e) => setClassNum(e.target.value);
  const studentNumHandler = (e) => setStudentNum(e.target.value);
  const nameHandler = (e) => setName(e.target.value);
  const onChangePasswordCheck = useCallback(
    (e) => {
      setPasswordCheck(e.target.value);
      setPasswordError(e.target.value !== password);
    },
    [password]
  );


  const onSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();
    var nameCheck = name;
    if (nameCheck == '') {
      nameCheck = propUsername;
    };
    var numCheck = studentNum;
    if (numCheck == '') {
      numCheck = propStudentNum;
    }
    dispatch(
      updateTeacherInfoAdminPromise({
        accountId: propUser_id,
        userName: nameCheck,
        studentNumber: numCheck,
      })
    ).then((userInfo) => {
        if (userInfo === undefined) {
          throw new Error("login failed");
        }
        dispatch(getAllStudentsAdminPromise(userInfo.schoolId));
        console.log("updateUserInfoPromise user info : ", userInfo);
        setShow(false);
      })
      .catch((error) => {
        console.log("userUpdate error", error);
      });
    setDisabled(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      className={styles["modal"]}
    >
      <Modal.Header closeButton>
        <Modal.Title>수정할 정보를 입력해주세요</Modal.Title>
      </Modal.Header>
      <div>
        <form onSubmit={onSubmit} className={styles['form']}>
          <div className={styles['outerContainer']}>
            {/* <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}> 계정 ID</div>
              <input
                type={'text'}
                value={account}
                onChange={accountHandler}
                className={styles['inputContainer']}
              />
            </div> */}
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>이름</div>
              <input
                type={'text'}
                value={name}
                onChange={nameHandler}
                placeholder={propUsername}
                className={styles['inputContainer']}
              //placeholder=" YYYYMMDD"
              />
            </div>
            {/* <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>이메일</div>
              <input
                type="text"
                name="email"
                value={email}
                onChange={onChangeEmail}
                className={styles['inputContainer']}
              />
            </div> */}
            {/* <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>학년</div>
              <input
                type={'text'}
                value={grade}
                onChange={gradeHandler}
                placeholder={propGrade}
                className={styles['inputContainer']}
              />
            </div>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>반</div>
              <input
                type={'text'}
                value={classNum}
                onChange={classNumHandler}
                placeholder={propClassName}
                className={styles['inputContainer']}
              />
            </div> */}
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>학번</div>
              <input
                type={'text'}
                value={studentNum}
                onChange={studentNumHandler}
                placeholder={propStudentNum}
                className={styles['inputContainer']}
              />
            </div>
            {/* <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>비밀번호</div>
              <input
                name="비밀번호"
                type="password"
                value={password}
                // required
                onChange={onChangePassword}
                className={styles['inputContainer']}
              // onChange={(e) => setPw(e.target.value)}
              />
            </div>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>비밀번호 재확인</div>
              <input
                type="password"
                name="비밀번호 재확인"
                value={passwordCheck}
                onChange={onChangePasswordCheck}
                className={styles['inputContainer']}
              // required
              />
            </div>
            {passwordError && (
              <ErrorMessage>비밀번호가 일치하지 않습니다. </ErrorMessage>
            )} */}
          </div>
          <button
            type="submit"
            name="submit"
            className={styles['button-input']}
            disabled={disabled}
          >
            수정하기
          </button>
        </form>

      </div>
    </Modal>
  );


}

export default InfoStudentUpdateModal;
