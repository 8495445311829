import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sidebar from '@components/Layout/Sidebar';
import styles from './index.module.scss';
import { changePasswordPromise, setSidebarState, getSchoolPromise } from '@store/actions';
import { setToken, getStorageData, useInterval } from '@api';

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const control = useSelector((state) => state.control);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const schoolId = user.schoolId;
    if (schoolId == null) {
      //link to focuspang school
      dispatch(getSchoolPromise(1424))
    } dispatch(getSchoolPromise(schoolId))
  }, []);

  useEffect(() => {
    if (user.token !== undefined || user.token !== null) {
      setToken(user.token).then(() => {
        try {
          console.log('set token to program...');
          window.cAPI.setToken(user.token);
        } catch (error) {}
      });
    }
    if (user.state === 'REGISTERED_APPROVED') {
      if (control.sidebarState === false) dispatch(setSidebarState());
    } else {
      //dispatch(setSidebarState(false));
    }
    // console.log('[Setting : ', control);
  }, [user, control]);
 
  return (
    <div className={styles['main-container']}>
      <Sidebar />
      <div className={styles['settings-container']}>
        <div className={styles['settings-title']}>Welcome to Focuspang</div>
        <div className={styles['subtitle']}>포커스팡 사용법에 대해 안내드리겠습니다🖐</div>
        <br />
        <div className={styles['step-title-box']}>
            <div className={styles['manual']}>1. 관리자용 포커스팡</div>
            <div className={styles['guide-video']}>
                <a href='https://youtu.be/N9mPBuicgRA' target="_black">관리자용 사용법 동영상</a>
            </div>
        </div>
        <div className={styles['step-box']} style={{ marginBottom: 8 }}>
            <div>⦁ 관리자의 역할은 기본적으로 포커스팡을 사용하는 교사의 계정 발급과 관리입니다.</div>
            <div>⦁ 관리자는 교사의 계정 생성, 정보 수정, 비밀번호 초기화, 그리고 계정의 정지 혹은 삭제를 할 수 있습니다.</div>
        </div>
        <div className={styles['step-box']} style={{ marginBottom: 8 }}>
            <div className={styles['step-box-title']}>계정을 만들기 전 유의사항</div>
            <div>⦁ 포커스팡을 사용하는 선생님용 계정을 만드실 때 학교 소속 교사인지 혹은 포커스팡에서 적법하게 활동을 하실 수 있는지 확인을 하시기 바랍니다.</div>
            <div>⦁ 교사용 계정을 만들기 전에, 개인정보 제공 동의 및 제 3자 제공 동의 절차를 모두 마무리하였는지 확인하고 진행하시기 바랍니다.</div>
            <div>⦁ 학교별 계정 관리의 책임과 의무는 학교에 있습니다. 이 점 유의하여 주시기 바랍니다.</div>
            <div>⦁ 교사용 개인정보 활용 및 제 3자 제공 동의 <a href='https://focuspang.com/consent_teacher.html' target="_black">https://focuspang.com/consent_teacher.html</a></div>
        </div>
        <div className={styles['step-box']} style={{ marginBottom: 8 }}>
            <div className={styles['step-box-title']}>관리자 계정 관리</div>
            <div>⦁ 관리자는 여러 명이 될 수 있습니다. 그러나 모두 동일한 권한을 가집니다.</div>
            <div>⦁ 관리자 계정은 선생님 계정으로도 사용 가능합니다.</div>
            <div>⦁ 관리자는 자신의 비번을 수정할 수 있습니다. 비번 수정은 왼쪽 하단의 [사용법 및 관리] 메뉴를 이용하시기 바랍니다.</div>
        </div>
        <div className={styles['step-box']} style={{ marginBottom: 8 }}>
            <div className={styles['step-box-title']}>선생님 계정 관리</div>
            <div>⦁ [계정 생성] 계정을 생성할 때,  이름과 이메일 주소만 있으면 됩니다. 다만, 이미 사용된 이메일 주소를 중복해서 사용할 수 없습니다.</div>
            <div>⦁ [계정 정지] 포커스팡을 사용 중인 선생님이 예를 들어 휴직한 경우에는 선생님의 계정을 정지시키는 것이 좋습니다. 계정이 정지되면 해당 교사는 포커스팡에 로그인을 할 수 없습니다.  [계정 활성] 상태로 변경되면, 로그인이 가능합니다.</div>
            <div>⦁ [계정 수정] 계정에 등록된 선생님의 이름, 이메일을 수정할 수 있습니다.</div>
            <div>⦁ [비번 초기화] 계정 수정 기능을 이용하여 계정 비밀번호를 초기화할 수 있습니다.</div>
            <div>⦁ [계정 삭제] 포커스팡을 사용중인 선생님이 예를 들어 전근, 퇴직, 계약종료 등이 된 경우에는 계정을 삭제하기 바랍니다. 계정을 삭제하면 포커스팡에 로그인을 할 수 없을 뿐만 아니라, 관련 계정 데이터가 모두 삭제되어 복구가 되지 않으니 유의하시기 바랍니다.</div>
        </div>
        <div className={styles['step-box']}>
            <div className={styles['step-box-title']}>학생 계정 관리</div>
            <div>⦁ 현재 관리자 기능에선 "학생의 계정 생성과 관리"는 관리자가 아닌 수업을 만들고 운영하시는 개별 교사의 역할입니다. 참고로, 각 교사가 수업을 만들고 학생을 추가할 때 학생의 계정이 생성되고 등록됩니다.</div>
            {/* <div>- 학생의 계정은 같은학번, 같은 이름으로는 등록이 불가능합니다.</div> */}
        </div>
        <br /><br />
        <div className={styles['step-title-box']}>
            <div className={styles['manual']}>2. 교사용 포커스팡</div>
            <div className={styles['guide-video']}>
                <a href='https://focuspang.com/guide_2.html' target="_black">교사용 사용법 가이드</a>
            </div>
        </div>
        <div className={styles['step-box']}>
            <div>⦁ 교사는 MS 윈도우 컴퓨터/서피스, MacBook, 크롬북의 크롬 브라우저, 웨일북의 웨일브라우저를 이용합니다.</div>
            <div>⦁ 교사용 계정은 학교별 관리자에게 발급신청해 주십시오.</div>
            <div>⦁ 안드로이드 갤럭시탭과 iPad의 크롬 브라우저는 지원하지 않습니다.</div>
        </div>
        <br /><br />
        <div className={styles['step-title-box']}>
            <div className={styles['manual']}>3. 학생용 포커스팡</div>
            <div className={styles['guide-video']}>
                <a href='https://focuspang.com/_dn_.html' target="_black">학생용 다운로드</a>
            </div>
        </div>
        <div className={styles['step-box']}>
            <div>⦁ 학생이 사용하는 os에 맞게 학생용 포커스팡을 이용합니다.</div>
            <div>⦁ 학생용 계정은 선생님이 생성하신 수업 코드와 학번, 이름을 이용해서 로그인할 수 있습니다.</div>
            <div>⦁ 안드로이드, MS Windows, 구글 크롬북, 웨일북 지원 중입니다.</div>
        </div>
        <br /><br /><br /><br />
        {/* <div style={{display: 'flex', alignItems: 'center'}}>
          <div>문의사항이 있으시면 care@focuspang.com으로 문의 부탁드립니다.</div>
          <div className={styles['guide-video']}>
              <a href='https://focuspang.com/ask.html' target="_black">포커스팡 즉문즉답</a>
          </div>
        </div> */}
        <div className={styles['settings-title']}>Need Help?</div>
        <div className={styles['subtitle']} style={{marginBottom:30}}>문의사항이 있으시면 이메일(care@focuspang.com)이나 즉문즉답으로 문의 부탁드립니다😊</div>
        <div style={{display:'flex',justifyContent: 'center'}}>
          <div className={styles['help-box']}>
              <a href='https://focuspang.com/ask.html' target="_black">포커스팡 즉문즉답</a>
          </div>
        </div>
        <br /><br />
      </div>
    </div>
  );
};

export default Settings;
