import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setToken } from '@api';
import { Link } from "react-router-dom";


const Home = (props) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  
  useEffect(() => {
    // console.log(history);
    history.replace("/", null);
    props.history.replace('/guide');
    // console.log(history);
    // if (user.token !== undefined && user.token !== null) {
    //   setToken(user.token);
    // }
  }, []);

  return (
    <div/>
  );
};

export default Home;
