import {
  PURGE_ALL,
  PURGE_USER,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,

  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_OUT,
  CHANGE_PW,
  CHANGE_PW_ERROR,
  RESTORE_USER,

  TEACHER_SIGN_UP_SUCCESS,
  TEACHER_SIGN_UP_FAIL,

  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  FILL_TEACHER_ACCOUNT_SUCCESS,
  FILL_TEACHER_ACCOUNT_FAIL,

  GET_MY_CLIENT_INFO_FAIL,
  GET_MY_CLIENT_INFO_SUCCESS,

  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAIL,
} from "./actionTypes";
import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeAll = () => ({
  type: PURGE_ALL,
});

///// PURGE_USER

export const purgeUser = () => ({
  type: PURGE_USER,
});

///// SIGN_UP

export const signUpPromise = createPromiseAction("SIGN_UP_PROMISE");

export const signUpSuccess = (profileBackend) => {
  return {
    type: SIGN_UP_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const signUpFail = (error) => ({
  type: SIGN_UP_FAIL,
  error: error,
});

///// SIGN_IN
export const signInPromise = createPromiseAction("SIGN_IN_PROMISE");

export const signInSuccess = (user) => {
  return {
    type: SIGN_IN_SUCCESS,
    user: {
      ...user,
      signedIn: true,
      updated: true,
    },
  };
};

export const signInFail = (error) => ({
  type: SIGN_IN_FAIL,
  error: error,
});

///// SIGN_OUT

export const signOut = () => ({
  type: SIGN_OUT,
});

///// Change PW

export const changePasswordPromise = createPromiseAction("CHANGE_PW_PROMISE");

export const changePassword = (password) => ({
  type: CHANGE_PW,
  payload: password,
});

export const changePasswordError = (error) => ({
  type: CHANGE_PW_ERROR,
  error: error,
});

///// RESTORE_USER

export const restoreUser = (profileData) => ({
  type: RESTORE_USER,
  profile: { ...profileData, signedIn: true },
});

///// TEACHER_SIGN_UP

export const teacherSignUpPromise = createPromiseAction(
  "TEACHER_SIGN_UP_PROMISE"
);

export const teacherSignUpSuccess = (profileBackend) => {
  return {
    type: TEACHER_SIGN_UP_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const teacherSignUpFail = (error) => ({
  type: TEACHER_SIGN_UP_FAIL,
  error: error,
});

/// UPDATE_USER 성현검토

export const updateUserPromise = createPromiseAction("UPDATE_USER_PROMISE");

export const updateUserSuccess = (userData) => ({
  type: UPDATE_USER_SUCCESS,
  classroom: api.unpackUser(userData),
});

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

//////FILL_TEACHER_ACCOUNT
export const fillTeacherAccountPromise = createPromiseAction(
  "FILL_TEACHER_ACCOUNT_PROMISE"
);

export const fillTeacherAccountSuccess = (profileBackend) => {
  return {
    type: FILL_TEACHER_ACCOUNT_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
    },
  };
};

export const fillTeacherAccountFail = (error) => ({
  type: FILL_TEACHER_ACCOUNT_FAIL,
  error: error,
});

//// UPDATE USER INFO
export const updateUserInfoPromise = createPromiseAction(
  "UPDATE_USER_INFO_PROMISE"
);

export const updateUserInfoSuccess = (userInfo) => {
  return {
    type: UPDATE_USER_INFO_SUCCESS,
    profile: {
      ...api.unpackClient(userInfo),
    },
  };
};

export const updateUserInfoFail = (error) => ({
  type: UPDATE_USER_INFO_FAIL,
  error: error,
});

//// GET_MY_CLIENT_INFO
export const getMyClientInfoPromise = createPromiseAction(
  "GET_MY_CLIENT_INFO_PROMISE"
);

export const getMyClientInfoSuccess = (userInfo) => {
  return {
    type: GET_MY_CLIENT_INFO_SUCCESS,
    profile: {
      ...api.unpackClient(userInfo),
    },
  };
};

export const getMyClientInfoFail = (error) => ({
  type: GET_MY_CLIENT_INFO_FAIL,
  error: error,
});