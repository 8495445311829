import { call, put, takeLeading } from 'redux-saga/effects';
import {
  getSchoolSuccess,
  getSchoolFail,
  getSchoolPromise,
 

} from '@store/actions'
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getSchoolPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getSchool, action.payload);
      yield put(getSchoolSuccess(response.data));
      return yield response.data ;
    } catch(error) {
      console.log('[getSchoolPromiseHandler]', error);
      yield put(getSchoolFail(error));
    }  
  });
}

function* schoolSaga() {
  yield takeLeading(getSchoolPromise, getSchoolPromiseHandler);
}

export default schoolSaga