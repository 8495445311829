import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Redirect, Route, useHistory } from "react-router-dom";

import styles from "./SidebarAuth.module.scss";

import SidebarClassList from "@components/Layout/SidebarClassList";

import imgClassListIcon from "@images/home/ic-class-list-24.svg";
import imgSettingIcon from "@images/home/ic_settings_24.svg";
import imgAddIcon from "@images/home/ic_add_24.svg";
import imgLightIcon from "@images/home/ic_lightbulb_24.svg";
import focuslogo from '../../assets/images/focuspang.svg';

const SidebarAuth = () => {
  var ClassListEnalble = false;
  const user = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (ClassListEnalble) return <SidebarClassList />;
  }, [ClassListEnalble]);

  const onPressClassList = () => {
    //history.replace("/add-classroom");
    ClassListEnalble = !ClassListEnalble;
    //
    // if(ClassListEnalble){
    //   return <SidebarClassList enabled = "true"/>
    // }else{
    //   return <SidebarClassList enabled = "false"/>
    // }
  };

  const addClass = () => {
    history.replace("/add-classroom");
    return;
  };

  const onPressStart = () => {
    history.replace("/home");
    return;
  };

  const onPressSettings = () => {
    history.replace("/settings");
    return;
  };

  return (
    <div className={styles["side-screen"]}>
      <div className={styles["inner-side"]}> 
        {/* <div className={styles["side-bar-line"]}>
          <div className={styles["user-info"]}>지나가는 나그네</div>
          <div className={styles["user-mail"]}>@gmail.com</div>
        </div> */}
        {/* <hr className={styles["side-bar-line"]} /> */}
        <div className={styles["home-block"]}>
          {/* <img className={styles["img-set"]} src={imgLightIcon} alt="home" /> */}
          {/* <div className={styles["span-sidebar"]}>시작하기</div> */}
          <img src={focuslogo} className={styles['logo-img']} /> 
          <div className={styles["span-sidebar"]}>포커스팡 관리자 선생님</div> 
        </div> 
        
        {/* <div className={styles["menu-block"]}>
          <img
            className={styles["img-set"]}
            src={imgClassListIcon}
            alt="class-list"
          />
          <div className={styles["span-classList"]}>수업 리스트</div>
          <img
            className={styles["img-set-add"]}
            src={imgAddIcon}
            alt="addclass"
          />
        </div> */}
        {/* <SidebarClassList /> */}

        {/* <div className={styles["setting-block"]}>
          <img className={styles["img-set"]} src={imgSettingIcon} alt="home" />
          <div className={styles["span-sidebar"]}>설정</div>
        </div> */}
      </div>
    </div>
  );
};

export default SidebarAuth;
