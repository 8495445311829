// import { REACT_APP_STATIC_ADDR } from '@env';

const StaticAddrSlash = process.env.REACT_APP_STATIC_ADDR;
const StaticAddr = StaticAddrSlash.substring(0, StaticAddrSlash.length - 1);

export const defaultIconPath = () => {
  return 'https://i.natgeofe.com/n/3861de2a-04e6-45fd-aec8-02e7809f9d4e/02-cat-training-NationalGeographic_1484324_square.jpg';
}
export const programIconPath = programAlias => {
  return StaticAddr + '/media/programs/' + programAlias + '.png';
}

export const getIcon = iconPath => {
  return StaticAddr + '/media/icons/' + iconPath + '.png';
}

export const getStickerIcon = iconPath => {
  return StaticAddr + '/media/stickers/' + iconPath + '.png';
}

// export const profileIconImgPath = iconName => {
//   return StaticAddr + '/media/icons-tmp/' + iconName + '.png';
// }