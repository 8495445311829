import { call, put, takeLeading } from "redux-saga/effects";
import {
  PURGE_ACCOUNT,
} from "./actionTypes";
import {
  registerTeacherSuccess,
  registerTeacherFail,
  registerTeacherPromise,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* registerTeacherPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.registerTeacher, action.payload);
      yield put(registerTeacherSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(registerTeacherFail(error));
    }
  });
}

function* accountSaga() {
  yield takeLeading(registerTeacherPromise, registerTeacherPromiseHandler);
}

export default accountSaga;