import React, { useEffect, useState } from 'react';
import styles from './CardUserInfo.module.scss';
import PersonIcon from '@mui/icons-material/Person';
import InfoUpdateModal from '@components/Modal/InfoUpdateModal';
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientAdminPromise,
  getAllTeachersAdminPromise,
  stopClientAdminPromise,
  reuseClientAdminPromise,
} from '@store/actions';
import { Tooltip } from '@mui/material';
import ReactGA from 'react-ga';

const CardUserInfo = ({ username, email, user_id, school_id, user_state }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [userStateText, setUserStateText] = useState(user_state === "REGISTERED_APPROVED" ? "일시 정지하기" : "정지 해제하기");
  const [button, setButton] = useState(false);

  useEffect(()=>{
    if (user?.accountId === user_id) {
      setButton(true)
    }
  },[]);

  const onStateClick = () => {
    if (user_state === "REGISTERED_APPROVED") {
        if (window.confirm("해당 계정을 정지시키겠습니까?")) {
            onState();
        } else {
            console.log("취소");
        }
        return;
    } 
    if (window.confirm("해당 계정을 활성시키겠습니까?")) {
        onState();
    } else {
        console.log("취소");
    }
  };

  const onState = () => {
    if (user_state === "REGISTERED_APPROVED") {//활성 상태일떄
      dispatch(stopClientAdminPromise({ accountId: user_id, schoolId:school_id, state:'REGISTERED_CERT'}))
        .then((result) => {
          console.log("상태 stop 됐는지 확인", result);
          setUserStateText(result.state === "REGISTERED_APPROVED" ? "일시 정지하기" : "정지 해제하기");
          dispatch(getAllTeachersAdminPromise(school_id))
            .then((teachersData) => {
              if (teachersData === undefined) {
                throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
    } else {//정지 상태일때
      dispatch(reuseClientAdminPromise({ accountId: user_id, schoolId:school_id, state:'REGISTERED_APPROVED'}))
        .then((result) => {
          console.log("상태 다시 활성화 됐는지 확인", result);
          setUserStateText(result.state === "REGISTERED_APPROVED" ? "일시 정지하기" : "정지 해제하기");
          dispatch(getAllTeachersAdminPromise(school_id))
            .then((teachersData) => {
              if (teachersData === undefined) {
                throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
    }
  }

  const onDelete = () => {
    if (window.confirm("해당 선생님 계정을 삭제하시겠습니까?\n관리자계정으로 사용중인 계정이라면 관리자계정도 삭제됩니다.\n삭제 시 계정 복구 불가능합니다.")) {
      navigateToDeleteTeacher();
    } else {
      console.log("취소");
    }
  }

  const navigateToDeleteTeacher = () => {
    dispatch(deleteClientAdminPromise({ id: user_id, })).then((result) => {
      console.log(result);
      alert("삭제하였습니다.");
      dispatch(getAllTeachersAdminPromise(school_id))
        .then((teachersData) => {
          if (teachersData === undefined) {
            throw new Error('already signed up!');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  return (
    <>
      <div className={styles['main-container']}>
        <div className={styles['main-container-userName-box']}>
          <div className={styles['main-container-img']}>
            <PersonIcon sx={{ fontSize: 50 }} />
          </div>
          <div className={styles['main-container-userName']}>
            <div>{username}</div>
          </div>
        </div>
        <div className={styles['email-container']}>{email}</div>
        <div className={styles['main-container-accountState']}>
          <div className={user_state === "REGISTERED_APPROVED" ? styles['account-state'] : styles['account-state2']}>
            {user_state === "REGISTERED_APPROVED" ? '사용 가능' : '사용 정지중'}
          </div>
        </div>
        <div className={styles['button-container']}>
          <Tooltip title='이름, 이메일, 비밀번호 수정이 가능합니다' arrow>
            <button
              className={styles['update-button']}
              onClick={() => { setShow(true); }}
            >
              수정
            </button>
          </Tooltip>
          <Tooltip title={user_state === "REGISTERED_APPROVED" ? '클릭 시 계정 사용이 정지되도록 변경 가능합니다' : '클릭 시 사용 가능한 계정으로 변경 가능합니다'} arrow>
            <button
              onClick={onStateClick}
              className={user_state === "REGISTERED_APPROVED" ? styles['state-button'] : styles['state-button2']}
            >{userStateText}</button>
          </Tooltip>
          <Tooltip title='계정 삭제 시 해당 계정은 사용 불가능해집니다' arrow>
          <button
            onClick={() => {
              onDelete();
              ReactGA.event({
                action: "Click Delete Teacher Account",
                category: "Button",
                label: "Teacher",
              });
            }}
            className={styles['delete-button']}
          >계정 삭제</button></Tooltip>
        </div>
      </div>
      <InfoUpdateModal show={show} setShow={setShow} propUsername={username} propEmail={email} propUser_id={user_id} />
    </>
  );
};

export default CardUserInfo;
