import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import styles from "./InviteTeacherModal.module.scss";
import useInput from '../../hooks/useInput';
import styled from 'styled-components';
import {
  createTeacherAdminPromise,
  getAllTeachersAdminPromise,
  createStudentAdminPromise,
  getAllStudentsAdminPromise,
} from '@store/actions';

const ErrorMessage = styled.div`
  color: red;
`;

function InviteStudentModal({ show, setShow, school_id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  // const [gender, setGender] = useState(true);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  // const [account, setAccount] = useState('');
  // const [birthToString, setBirthToString] = useState('');
  const [disabled, setDisabled] = useState(false);
  // const [email, onChangeEmail] = useInput('');
  // const [emailCheck, setEmailCheck] = useState('');
  // const [emailMessage, setEmailMessage] = useState('');
  // const [phone, onChangePhone] = useInput('');
  // const [password, onChangePassword] = useInput('');
  // const [passwordCheck, setPasswordCheck] = useState('');
  // const [passwordError, setPasswordError] = useState(false);
  // const [agreeChkbox, setAgreeChkbox] = useState(true); //제 3자 정보 제공 활용에 동의 체크 박스
  // const [privacyChkbox, setprivacyChkbox] = useState(true); //개인정보 동의 약관 체크 박스
  // const [anotherChkbox, setanotherChkbox] = useState(true); //제 3자 정보 제공 활용에 동의 체크 박스
  // const accountHandler = (e) => setAccount(e.target.value);
  const nameHandler = (e) => setName(e.target.value);
  const numberHandler = (e) => setNumber(e.target.value);
  // const onChangePasswordCheck = useCallback(
  //   (e) => {
  //     setPasswordCheck(e.target.value);
  //     setPasswordError(e.target.value !== password);
  //   },
  //   [password]
  // );
  // const onChangeEmailCheck = useCallback((e) => {
  //   const emailRegex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
  //   const emailCurrent = e.target.value

  //   if (!emailRegex.test(emailCurrent)) {
  //     setEmailMessage('이메일 형식이 틀렸어요. 다시 입력해주세요')
  //   } else {
  //     setEmailMessage('올바른 이메일 형식이에요')
  //     setEmailCheck(e.target.value)
  //   }
  // }, [])

  const nameRegex = /^[가-힣]+$/;

  const onSubmit = (e) => {
    console.log('버튼눌림?',name,number)
    setDisabled(true);
    e.preventDefault();
    if (nameRegex.test(name)==false)  {
      alert('이름은 한글로만 채워주세요');
    } else
    if (name === '' || number === '')  {
      alert('학생의 이름, 학번 모두 채워주세요');
    } else {
      var payload = {
        schoolId: school_id,
        groupId: '12fb734c-341b-4cd7-a158-51ba058e13a7',
        userName: name, 
        studentNumber: number,
        userId: '',
        password: '',
      }
      dispatch(createStudentAdminPromise(payload))
        .then((clientData) => {
          if (clientData === undefined) {
            throw new Error('already signed up!');
          }
          dispatch(getAllStudentsAdminPromise(school_id))
            .then((studentsData) => {
              if (studentsData === undefined) {
                throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
          // alert('등록실패');  
          //근데 여기로 빠지는데 생성은 됨. 그리고 새로고침해야 목록에 반영됨;;
        });
      setShow(false);
    }
    setDisabled(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      className={styles["modal"]}
    >
      <Modal.Header closeButton>
        <Modal.Title>학생 정보를 입력해주세요</Modal.Title>
      </Modal.Header>
      <div>
        <form onSubmit={onSubmit} className={styles['form']}>
          <div className={styles['outerContainer']}>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>이름</div>
              <input
                type="text"
                name="name"
                onChange={nameHandler}
                className={styles['inputContainer']}
              />
            </div>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>학번</div>
              <input
                type="text"
                name="number"
                onChange={numberHandler}
                className={styles['inputContainer']}
                placeholder="ex) 1학년1반1번 -> 10101"
              />
            </div>
         
          </div>
          <button
            type="submit"
            name="submit"
            className={styles['button-input']}
            disabled={disabled}
          >
            등록하기
          </button>
        </form>

      </div>
    </Modal>
  );


}

export default InviteStudentModal;
