import { makeFreshAllIds } from '../helpers';
import {
  PURGE_SCHOOL,
  
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_FAIL,

} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
  byIdFilter: {},
  allFilteredIds: [],
  // todo: temp
  fetchedAllSchools: false,
  selectedSchoolId: null,
  decipherSchool: {},
};

const schools = (state=initialState, action) => {
  switch(action.type) {
    case PURGE_SCHOOL:
      return initialState;

    case GET_SCHOOL_SUCCESS:
      return {...state,
        byId: {...state.byId,
          [action.school.schoolId]: action.school},
        allIds: makeFreshAllIds(state.allIds, [action.school.schoolId])};

    case GET_SCHOOL_FAIL:
      console.error('[School reducer]', action.payload);
      return state
      
    default:
      break;
  }
  return state;
};

export default schools;
