import React from 'react';
import { Redirect } from 'react-router-dom';

// Pages Component
import Home from '@pages/Home';
import SignIn from '@pages/Auth';
import AuthManager from '@managers/AuthManager';
import Teacher from '@pages/Teacher';
import Student from '@pages/Student';
import Settings from '@pages/Settings';
import Manager from '@pages/Manager';
import Guide from '@pages/Guide';

const authProtectedRoutes = [
  { path: '/home', component: Home },
  { path: '/auth', component: SignIn },
  { path: '/auth-manager', component: AuthManager },
  { path: '/teacher' , component: Teacher },
  { path: '/student', component: Student },
  { path: '/settings', component: Settings },
  { path: '/manager', component: Manager },
  { path: '/guide', component: Guide },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () => (
      <Redirect
        to={{
          pathname: '/auth-manager',
        }}
      />
    ),
  },
];

const publicRoutes = [{ 
  path: '/student', component: Student }
];

export { authProtectedRoutes, publicRoutes };
