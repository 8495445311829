import { authProtectedRoutes } from '@routes';
import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
// import { store, rrfProps } from "@store";
import { store } from '@store/index';
import { Switch, BrowserRouter } from 'react-router-dom';
import AppRoute from '@routes/route';
import { setToken, getStorageData } from '@api';
import { PersistGate } from 'redux-persist/integration/react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

const App = (props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  
  const refreshToken = async () => {
    const user_raw = await getStorageData('@user');
    const user = JSON.parse(user_raw);
    // console.log('GET USER INFO: ', user);
    if (user == null) {
      console.log('fresh install...');
      setLoading(false);
    } else {
      if (user.token !== null && user.token !== undefined) {
        console.log('restoring user...');
        setToken(user.token).then(() => {
          console.log('token restored.');
          setLoading(false);
        });
      }
    }
  };

  const persistor = persistStore(store, {}, refreshToken);

  useEffect(() => {
    ReactGA.initialize("G-1W4T78FC92");
    history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Provider store={store}>
      {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Switch>
            {authProtectedRoutes.map((route, idx) => {
              return (
                <AppRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              );
            })}
          </Switch>
        </BrowserRouter>
      </PersistGate>
      {/* </ReactReduxFirebaseProvider> */}
    </Provider>
  );
};

export default App;
