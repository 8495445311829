import { call, put, takeLeading } from "redux-saga/effects";
import {
  PURGE_ALL,
  SIGN_OUT,
  RESTORE_USER,
} from "./actionTypes";
import {
  teacherSignUpPromise,
  signInPromise,
  signInSuccess,
  signInFail,
  signOut,
  changePasswordPromise,
  changePasswordError,
  purgeClient,
  purgeGroup,
  purgeUser,

  teacherSignUpSuccess,
  teacherSignUpFail,
  purgeSchool,
  purgeMembership,
  purgeControl,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* purgeAllHandler() {
  try {
    console.log("Purging all redux...");
    yield put(signOut());
    yield put(purgeUser());
    yield put(purgeClient());
    yield put(purgeGroup());
    yield put(purgeControl());
    yield put(purgeSchool());
    yield put(purgeMembership());
  } catch (error) {
    console.error(error);
  }
}

function* signInPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const signInData = action.payload;
      const response = yield call(api.signIn, signInData);
      if(response.data.client.state!='REGISTERED_APPROVED') {
        alert(signInData.email + '은 정지된 관리자계정입니다');
        yield put(signInFail(response));
        return;
      }
      const userData = api.unpackUser(response.data.account, response.data.client);
      // yield call(api.setToken, clientInfo.token);
      yield call(api.storeStorageData, "@user", userData);
      yield call (api.setToken, response.data.jwt.token);
      yield put(signInSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(signInFail(error));
    }
  });
}

function* signOutHandler() {
  try {
    // todo: clear redux
    yield call(api.resetToken);
  } catch (error) {
    console.error(error);
  }
}

function* changePwPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.findPasswordClient, action.payload);
      return response.data;
    } catch (error) {
      yield put(changePasswordError(error));
    }
  });
}

function* restoreUserHandler({ profile: profileData }) {
  try {
    console.log("Restoring from session...");
    // todo: this packs then immediately unpacks profileData.
    // todo: should fix this
    yield call(api.setToken, sessionStorage.getItem("token"));
  } catch (error) {
    console.error(error);
  }
}

function* teacherSignUpHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      console.log(action.payload);
      const newClient = action.payload;
      const response = yield call(api.registerTeacher, newClient);
      // const userInfo = api.unpackClient(response.data);
      // yield call(api.setToken, userInfo.token);
      // yield call(api.storeStorageData, "@user", userInfo);
      yield put(teacherSignUpSuccess(api.unpackUser(response.data)));
      return yield response.data;
    } catch (error) {
      yield put(teacherSignUpFail(error));
    }
  });
}

function* userSaga() {
  yield takeLeading(PURGE_ALL, purgeAllHandler);
  yield takeLeading(teacherSignUpPromise, teacherSignUpHandler);
  yield takeLeading(signInPromise, signInPromiseHandler);
  yield takeLeading(changePasswordPromise, changePwPromiseHandler);
  yield takeLeading(SIGN_OUT, signOutHandler);
  yield takeLeading(RESTORE_USER, restoreUserHandler);
}

export default userSaga;