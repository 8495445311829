import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './CardSidebarLectureInfo.module.scss';
import imgSubjectIcon from '@images/home/ic-class-subject-24.svg';
import imgHRIcon from '@images/home/ic-class-hr-24.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useLocation } from 'react-router-dom';

const CardSidebarLectureInfo = ({ classInfo }) => {
  const setIcon = (lectureName) => {
    if (lectureName.includes('HR')) {
      return imgHRIcon;
    } else {
      return imgSubjectIcon;
    }
  };

  const history = useHistory();

  const navigateToEditMode = () => {
    //console.log(classInfo);
    // console.log("location : ", location);
    // if(classInfo.kind === "HR"){

    // }else{
    //   console.log("[SidebarClick :]",classInfo)
    //   history.push({
    //     pathname: "/edit-classroom",
    //     state: { classInfo: classInfo },
    //   });
    // }

    history.push({
      pathname: '/edit-classroom',
      state: { classInfo: classInfo },
    });
  };
  const navigateToClassRoom = () => {
    if (classInfo.kind === 'AFTER') {
      history.push({
        pathname: '/after-school/',
        //pathname: `/classroom/${classInfo.grade}${classInfo.class_name}`,
        state: {
          classInfo: classInfo,
        },
      });
    } else if (classInfo.kind === 'THUMB') {
      history.push({
        pathname: '/thumbnail-classroom/',
        //pathname: `/classroom/${classInfo.grade}${classInfo.class_name}`,
        state: {
          classInfo: classInfo,
        },
      });
    } else {
      history.push({
        pathname: '/classroom/',
        //pathname: `/classroom/${classInfo.grade}${classInfo.class_name}`,
        state: {
          classInfo: classInfo,
        },
      });
    }
  };

  return (
    <div className={styles['side-class-container']}>
      <div className={styles['side-class-list']} onClick={navigateToClassRoom}>
        <img
          className={styles['side-class-list-img']}
          alt="classroom-kind"
          src={setIcon(classInfo.kind)}
        />
        <div className={styles['side-class-grade']}>
          {classInfo.grade}학년 {classInfo.class_name}반
        </div>
        <div className={styles['side-class-name']}>
          {classInfo.kind !== 'AFTER' ? classInfo.name : ''}
        </div>
      </div>
      <div className={styles['pencil-icon']}>
        <EditOutlinedIcon onClick={navigateToEditMode} />
      </div>

      <br />
    </div>
  );
};

export default CardSidebarLectureInfo;
