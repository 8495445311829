export const selectAllMemberships = (state) => {
    if(state.membership.allIds.length === 0) {
        return [];
    }
    return state.membership.allIds?.map(membership => state.membership.byId[membership]);
}

export const  selectTeacherMemberships = (state) => {
	return selectAllMemberships(state).filter(membership => membership.role == 'TEACHER');
} 

export const  selectTeacherMembershipByGroup = (state, group) => {
    return selectAllMemberships(state).find(membership => membership.role == 'TEACHER' 
    && group.groupId == membership?.groupId);
} 