import React, { useEffect, useState } from 'react';
import CardSidebarLectureInfo from '@components/Card/CardSidebarLectureInfo';
import styles from './SidebarClassList.module.scss';

import { selectMyGroupsAsAdmin } from '@store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getMyGroupsPromise, getMyGroups } from '@store/actions';
const SidebarClassList = () => {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState();
  // console.log('enabled sidebar :',probs.enabled);
  const classes = (groups) => {
    //console.log('enabled sidebar2:',probs.enabled);
    return groups.map((c) => {
      return <CardSidebarLectureInfo key={c.id} classInfo={c} />;
    });
  };
  const allMyGroups = useSelector((state) =>
    selectMyGroupsAsAdmin(state, state.user)
  );
  useEffect(() => {
    dispatch(getMyGroupsPromise()).then((response) => {
      console.log('[SidebarClassGetmygroup]:', response);
      setGroups(response);
    });
  }, []);

  return (
    <div className={styles['side-class-menu-wrap']}>
      <div className={styles['side-class-menu']}>{classes(allMyGroups)}</div>
    </div>
  );
};

export default SidebarClassList;
