import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '@components/Layout/Sidebar';
import { setToken } from '@api';
import styles from './index.module.scss';
import CardUserInfo from '@components/Card/CardUserInfo';
import InviteTeacherModal from '@components/Modal/InviteTeacherModal';
import {
  getAllTeachersAdminPromise,
  getSchoolPromise
} from '@store/actions';
import useInput from '../../hooks/useInput';

const Teacher = (props) => {
  const user = useSelector((state) => state.user);
  const list = useSelector((state) => state.clients.allTeacher); // teacher + manager
  const schoolData = useSelector((state) => state.schools.byId[user.schoolId]);

  let schoolMaxData = 0;
  if (schoolData !== undefined && schoolData.maxTeacherAccount !== null) {
    schoolMaxData = schoolData.maxTeacherAccount;
  };

  let school_name = "";
  if (schoolData !== undefined) {
    school_name = schoolData.name;
  }

  const schools = useSelector((state) => state.schools);
  const [update, setUpdate] = useState();
  const [searchInput, onChangeSearchInput] = useInput('');
  //upNum,downNum,upName,downName
  const [sortTag, setSortTag] = useState("upName");
  const [sortAllmyStudent, setsortAllmyStudent] = useState([]);
  // const [maxAccount, setMaxAccount] = useState();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  let teachers_num = list?.length;

  useEffect(() => {
    const schoolId = user.schoolId;
    if (schoolId == null) {
      //link to focuspang school
      dispatch(getSchoolPromise(1424))
    } dispatch(getSchoolPromise(schoolId))
  }, []);

  useEffect(() => {
    return setsortAllmyStudent(list)
  }, [list])

  useEffect(() => {
    if (sortTag === "upNum") {
      // console.log("눌렸다", sortTag);
      const sortUpGradeStudent = list?.sort(function (a, b) {
        if (a.grade === b.grade) {
          if (a.class_name < b.class_name) {
            return -1;
          } else if (a.class_name > b.class_name) {
            return +1;
          } else {
            if (parseInt(a.student_number) < parseInt(b.student_number)) {
              return -1;
            } else if (parseInt(a.student_number) > parseInt(b.student_number)) {
              return +1;
            } else return 0;
          }
        } else return a.grade - b.grade;
      });
      return setsortAllmyStudent(sortUpGradeStudent);
    }

    else if (sortTag === "downNum") {
      // console.log("눌렸다", sortTag);
      const sortDownGradeStudent = list?.sort(function (a, b) {
        if (a.grade === b.grade) {
          if (a.class_name < b.class_name) {
            return +1;
          } else if (a.class_name > b.class_name) {
            return -1;
          } else {
            if (parseInt(a.student_number) < parseInt(b.student_number)) {
              return +1;
            } else if (parseInt(a.student_number) > parseInt(b.student_number)) {
              return -1;
            } else return 0;
          }
        } else return b.grade - a.grade;
      });
      return setsortAllmyStudent(sortDownGradeStudent);
    }

    else if (sortTag === "upName") {
      // console.log("눌렸다", sortTag);
      const sortUpNameStudent = list?.sort(function (a, b) {
        if (a.username < b.username) {
          return -1;
        } else if (a.username > b.username) {
          return +1;
        } else return 0;
      });
      // console.log('upName', sortUpNameStudent);
      return setsortAllmyStudent(sortUpNameStudent);
    }

    else if (sortTag === "downName") {
      // console.log("눌렸다", sortTag);
      const sortDownNameStudent = list?.sort(function (a, b) {
        if (a.username < b.username) {
          return +1;
        } else if (a.username > b.username) {
          return -1;
        } else return 0;
      });
      // console.log('downname', sortDownNameStudent);
      return setsortAllmyStudent(sortDownNameStudent);
    }
  }, [sortTag, sortAllmyStudent]);

  const onSearch = useCallback(() => {
    if (searchInput === null || searchInput === '') {
      setUpdate(list);
    } else {
      // console.log('search', searchInput)
      const result = list.filter(list => list.client.userName.includes(searchInput) || list.account.email.includes(searchInput));
      // console.log(update)
      setUpdate(result);
    }
  }, [searchInput]);

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      onSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  useEffect(() => {
    if (user.token !== undefined && user.token !== null) {
      setToken(user.token);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllTeachersAdminPromise(user.schoolId))
      .then((teachersData) => {
        if (teachersData === undefined) {
          throw new Error('already signed up!');
        }
      })
      .catch((error) => {
        console.error('[getAllTeachersAdminPromise Error]', error);
      });
  }, []);

  return (
    <div className={styles['main-container']}>
      <Sidebar auth={true} />
      <div className={styles['top-container']}>
        <div className={styles['box']}>
          <div>
            <div className={styles['title-text']}>
              <a href='/teacher'>선생님 계정 관리</a>
            </div>
            <div className={styles['license-text']}>(사용 {list?.length}명 / 최대가능 {schoolMaxData}명)</div>
          </div>
          <div className={styles['top-container-list']}>
            <input
              className={styles["member-search"]}
              type="text"
              placeholder="선생님 이름 또는 이메일"
              value={searchInput}
              onChange={onChangeSearchInput}
              onKeyPress={handleOnKeyPress}
            />
            <button
              className={styles['search-filter']}
              type="submit"
              onClick={onSearch}
            >
              검색
            </button>
          </div>
        </div>
      </div>

      <div className={styles['bottom-container']}>
        <div className={styles['bottom-container-list']}>
          <div className={styles['userName']}>
            이름
            {/* <button
              onClick={() => { sortTag === "upName" ? setSortTag("downName") : sortTag === "downName" ? setSortTag("upName") : setSortTag("upName") }
              }
            >
              {sortTag === "upName" ? "🔻" : sortTag === "downName" ? "🔺" : "➖"}
            </button> */}
          </div>
          <div className={styles['userEmail']}>이메일</div>
          <div className={styles['userState']}>계정상태</div>
          <div className={styles['userAdd']}>
            <button
              className={styles['userInvite-button']}
              onClick={() => {
                if (teachers_num >= schoolMaxData) {
                  alert('최대 ' + schoolMaxData + '명의 선생님만 등록할 수 있습니다. 계정 추가 신청은 문의부탁드립니다.');
                  console.log("user add cancelled");
                  return;
                }
                setShow(true);
              }}
            >
              선생님 등록하기
            </button>
          </div>
        </div>
        {
          update ?
            update?.map((data) => (
              <CardUserInfo
                key={data.account.accountId}
                data={data}
                username={data.client.userName}
                email={data.account.email}
                user_id={data.account.accountId}
                school_id={data.client.schoolId}
                //user_id={data.client.clientId}
                user_state={data.client.state} />
            ))
            :
            sortAllmyStudent?.map((data) => (
              <CardUserInfo
                key={data.account.accountId}
                data={data}
                username={data.client.userName}
                email={data.account.email}
                user_id={data.account.accountId}
                school_id={data.client.schoolId}
                user_state={data.client.state} />
            ))
        }

      </div>

      <InviteTeacherModal show={show} setShow={setShow}
        school_id={user.schoolId}
      />
      <br />
    </div>
  );
};

export default Teacher;
